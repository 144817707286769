// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Hero from "../components/hero"
import Block from "../components/block90vh"
import Img from "../assets/images/bgHeroGarantia.jpg"
import Data from "../data/garantiaVentaRepuestos"
import Formulario from "../components/multiFormulario/multitab"
import ImgGarantia from "../assets/images/garantiaContacto.jpg"
export default function garantiaVentaDeRepuestos() {
  return (
    <>
      <Helmet>
        <title>GARANTÍA Y VENTA DE REPUESTOS</title>
        <meta name="description" content="GARANTÍA Y VENTA DE REPUESTOS"></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title="GARANTÍA Y VENTA DE REPUESTOS"></Hero>
        <Block boton={true} data={Data}></Block>
        <Formulario title1='Garantía' title2='Venta de repuestos' img1={ImgGarantia} img2={ImgGarantia}/>
      </Layout>
    </>
  )
} 
